<template>
  <el-card class="box-card full-screen">
    <div ref="pageHead" class="table-page-head">
      <el-form :inline="true" :model="queryForm">
        <!-- <el-form-item :label="$t('orgName')">
          <el-select v-model="queryForm.orgInfoCode" :placeholder="$t('all')" filterable size="small">
            <el-option :label="$t('all')" value=""></el-option>
            <el-option v-for="(t,i) in orgList" :key="i" :label="t.name+' -- '+(t.orgInfoCode)" :value="t.orgInfoCode"></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item :label="$t('orgName')">
          <el-input v-model="queryForm.name" size="small" :placeholder="$t('orgName')"></el-input>
        </el-form-item>
        <!-- <el-form-item :label="$t('account')">
          <el-input v-model="queryForm.name" size="small"></el-input>
        </el-form-item> -->
        <!-- <el-form-item :label="$t('accountType')">
          <el-select v-model="queryForm.orgType" size="small">
            <el-option :label="$t('all')" value=""></el-option>
            <el-option :label="$t('directSaleStore')" value="2"></el-option>
            <el-option :label="$t('allianceBusiness')" value="3"></el-option>
          </el-select>
        </el-form-item> -->
        <!-- 区域选择器 -->
        <el-form-item :label="$t('region')">
          <el-select v-model="queryForm.region" size="small">
            <el-option :label="$t('all')" value=""></el-option>
            <template v-for="(item,index) in countryList">
              <el-option-group v-if="item.parentId==0" :key="index" :label="item.name">
                <template v-for="(it,i) in countryList">
                  <el-option v-if="it.parentId==item.id" :key="i+index.toString()" :label="it.name" :value="it.id"></el-option>
                </template>
              </el-option-group>
            </template>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="small" @click="getList" :loading="isLoading"><i class="el-icon-search"></i> {{$t('query')}}</el-button>
        </el-form-item>
      </el-form>
      <el-row class="table-btns">
        <el-button type="text" size="mini" icon="el-icon-edit" @click="drawerOpen">{{$t('addOrg')}}</el-button>
      </el-row>
    </div>
    <div class="data-table" :style="{top:queryTableHeight,height:`calc(100% - ${queryTableHeight} - 70px)`}">
      <el-table :data="tableData.records" v-loading="isLoading" element-loading-background="rgba(255,255,255,0.4)" size="mini" height="100%">
        <el-table-column prop="name" fixed="left" :label="$t('orgName')" width="200">
          <template slot-scope="scope">{{scope.row.name}}({{scope.row.orgInfoCode}})</template>
        </el-table-column>
        <el-table-column prop="orgType" :label="$t('orgType')" width="160">
          <template slot-scope="scope">
            <span v-if="scope.row.orgType==1">{{$t('platform')}}</span>
            <span v-if="scope.row.orgType==2">{{$t('directSaleStore')}}</span>
            <span v-if="scope.row.orgType==3">{{$t('allianceBusiness')}}</span>
            <span v-if="scope.row.orgType==5">{{$t('personal')}}</span>
            <span v-if="scope.row.orgType==6">{{$t('partnerAccount')}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="bankAccount" :label="$t('bankAccount')" width="160"></el-table-column>
        <el-table-column prop="bankName" :label="$t('bankName')" width="160"></el-table-column>
        <el-table-column prop="status" :label="$t('status')" width="160">
          <template slot-scope="scope">
            <span v-if="scope.row.status==0" class="text-success">{{$t('normal')}}</span>
            <span v-if="scope.row.status==1" class="text-danger">{{$t('lock')}}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="orgType" :label="$t('businessLicenseCode')" width="220"></el-table-column> -->
        <el-table-column prop="businessLicenseNo" :label="$t('businessLicenseCode')" width="220"></el-table-column>
        <el-table-column prop="businessLicensePic" :label="$t('businessLicenseImage')" width="220">
          <template slot-scope="scope">
            <el-image style="max-height:23px;" :src="scope.row.businessLicensePic" :preview-src-list="[scope.row.businessLicensePic]">
              <div slot="error" class="image-slot">
                <img :src="imgError" style="max-height:23px;vertical-align: text-top;">
              </div>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="legalpersonName + legalpersonSurname" :label="$t('legalPerson')" width="200">
          <template slot-scope="scope">{{scope.row.legalpersonName}} {{scope.row.legalpersonSurname}}</template>
        </el-table-column>
        <el-table-column prop="legalpersonCard" :label="$t('legalPersonIdCard')" width="160"></el-table-column>
        <el-table-column prop="legalpersonImg" :label="$t('legalPersonImage')" width="160">
          <template slot-scope="scope">
            <el-image style="max-height:23px;" :src="scope.row.legalpersonImg" :preview-src-list="[scope.row.legalpersonImg]">
              <div slot="error" class="image-slot">
                <img :src="imgError" style="max-height:23px;vertical-align: text-top;">
              </div>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="linkPhone" :label="$t('linkPhone')" width="160"></el-table-column>
        <el-table-column prop="country" :label="$t('nation')+' / '+$t('region')" width="220">
          <template slot-scope="scope">
            {{getNationProvinceUrban(scope.row)}}
          </template>
        </el-table-column>
        <el-table-column prop="orgAddress" :label="$t('orgAddress')" width="240">
          <template slot-scope="scope">
            <div class='overstep-hidden'>{{scope.row.orgAddress}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="remark" :label="$t('remark')" min-width="200">
          <template slot-scope="scope">
            <div class='overstep-hidden'>{{scope.row.remark}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="registerTime" :label="$t('registerTime')" width="200">
          <template slot-scope="scope">{{$manba(scope.row.registerTime).format('ymdhms')}}</template>
        </el-table-column>
        <el-table-column fixed="right" :label="$t('extend')" width="140">
          <template slot-scope="scope">
            <el-button v-if="scope.row.status==0" @click="onLock(scope.row,1)" size="small" type="primary">{{$t('lock')}}</el-button>
            <el-button v-if="scope.row.status==1" @click="onLock(scope.row,0)" size="small" type="warning">{{$t('unlock')}}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination background layout="total,sizes,prev,pager,next,jumper" :total="tableData.total||0" :page-sizes="[20, 30, 50, 100, 200]" :page-size="pageSize" :current-page="currentPage" @size-change="setPageSzie" @current-change="setCurrentChange" @prev-click="setCurrentChange" @next-click="setCurrentChange" />

    <el-drawer :title="editForm.id?$t('editAccount'):$t('addAccount')" :visible.sync="drawer" :direction="'rtl'" :modal="false" :destroy-on-close="true" :wrapperClosable="false" :size="700">
      <el-form :model="editForm" :rules="rules" ref="editForm">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item :label="$t('orgName')" prop="orgName">
              <el-input size="medium" v-model="editForm.orgName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('accountType')" prop="type">
              <el-select size="medium" v-model="editForm.type" disabled style="width:100%;">
                <el-option :label="$t('directStoreAccount')" :value="2"></el-option>
                <!-- <el-option :label="$t('franchiseStoreAccount')" :value="3"></el-option> -->
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('loginAccount')" prop="loginName">
              <el-input size="medium" v-model="editForm.loginName" :placeholder="$t('loginAccount')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('loginPassword')" prop="password">
              <el-input size="medium" v-model="editForm.password" placeholder="******"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('user')+$t('lastName')" prop="surname">
              <el-input size="medium" v-model="editForm.surname" :placeholder="$t('lastName')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('user')+$t('firstName')" prop="name">
              <el-input size="medium" v-model="editForm.name" :placeholder="$t('firstName')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('post')" prop="job">
              <el-input size="medium" v-model="editForm.job" :placeholder="$t('post')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('idCard')" prop="idCard">
              <el-input size="medium" v-model="editForm.idCard" :placeholder="$t('idCard')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('linkPhone')" prop="phoneNo">
              <el-input size="medium" v-model="editForm.phoneNo" :placeholder="$t('phone')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('role')" prop="roleId">
              <el-select size="medium" v-model="editForm.roleId" filterable placeholder="Enter the keyword to filter" style="width:100%;">
                <el-option v-for="item in roleList" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('org')+$t('remark')" prop="remark">
              <el-input size="medium" type="textarea" rows="3" v-model="editForm.remark" :placeholder="$t('org')+$t('remark')"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item class="bottom-button">
          <el-button v-if="editForm.id" size="medium" type="primary" :loading="isLoading" @click="onUpdateSubmit('editForm')">{{$t('update')}}</el-button>
          <el-button v-else size="medium" type="primary" :loading="isLoading" @click="onSubmit('editForm')">{{$t('submit')}}</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
    <!-- 锁定/解锁账户弹框 -->
    <el-drawer :title="$t(lockAccount.form.status==1?'lockAccount':'unlockAccount')" :visible.sync="lockAccount.drawer" :direction="'rtl'" :modal="false" :destroy-on-close="true" :wrapperClosable="false" :size="700">
      <el-form :model="lockAccount.form" :rules="rules" ref="lockAccount">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item :label="$t('orgName')">
              <el-input v-model="lockAccount.row.userName" size="small" disabled></el-input>
            </el-form-item>
            <el-form-item :label="$t('orgType')">
              <el-input v-model="lockAccount.row.orgTypeName" size="small" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('orgCode')">
              <el-input v-model="lockAccount.row.orgInfoCode" size="small" disabled></el-input>
            </el-form-item>
            <el-form-item :label="$t('linkPhone')">
              <el-input v-model="lockAccount.row.linkPhone" size="small" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <el-form-item :label="$t('reason')" prop="casue">
          <el-select v-model="lockAccount.form.casue" filterable>
            <el-option :label="'原因1'" :value="'原因1'"></el-option>
            <el-option :label="'原因2'" :value="'原因2'"></el-option>
            <el-option :label="'原因3'" :value="'原因3'"></el-option>
            <el-option :label="'原因4'" :value="'原因4'"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('remark')" prop="remark">
          <el-input type="textarea" :row="2" v-model="lockAccount.form.remark"></el-input>
        </el-form-item> -->
      </el-form>
      <div class="bottom-button">
        <el-button size="medium" type="primary" :loading="isLoading" @click="onSubmitLock('lockAccount')">{{$t(lockAccount.form.status==1?'lockAccount':'unlockAccount')}}</el-button>
      </div>
    </el-drawer>
  </el-card>
</template>
<script>
import org from '@/api/org'
import role from '@/api/role'
export default {
  name: "directSaleStore",
  data () {
    return {
      queryForm: {
        orgType: 2
      },
      //table高度，动态设置
      queryTableHeight: String,
      //table显示内容
      tableData: {},
      //分页器默认参数
      pageSize: 20,
      currentPage: 1,
      //显示加载中
      isLoading: false,
      //右侧弹框
      drawer: false,
      roleList: new Array(),
      editForm: { type: 2 },

      orgList: [],

      lockAccount: {
        drawer: false,
        form: {},
        row: {}
      },
    };
  },
  computed: {
    rules () {
      return {
        orgName: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        type: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        loginName: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        password: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        job: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        surname: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        name: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        idCard: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        phoneNo: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        roleId: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
      }
    },
    imgError () { return this.$store.state.system.imgError; },
    selectElements () {
      let _this = this;
      let list = JSON.parse(JSON.stringify(_this.countryList));
      let tree = list.filter(function (t) { return t.parentId === 0 });
      for (let i = 0; i < tree.length; i++) {
        tree[i].children = list.filter(function (t2) { return tree[i].id == t2.parentId });
      }
      return tree;
    },
    countryList () { return this.$store.state.globalParameter.countryList },
    loginInfo () { return this.$store.state.globalParameter.loginInfo; }
  },
  methods: {
    /**查询列表 */
    getList () {
      let _this = this;
      _this.isLoading = true;
      org.getOrgInfoPage({
        param: { current: _this.currentPage, size: _this.pageSize, ..._this.queryForm },
        success: res => {
          _this.tableData = res.data || {};
          _this.isLoading = false;
        }
      })
    },
    getOrgList () {
      let _this = this;
      org.getOrgInfoPage({
        param: { current: 1, size: 10000 },
        success: res => {
          _this.orgList = res.data.records || {};
        }
      })
    },
    setPageSzie (val) {
      this.pageSize = val;
      this.getList();
    },
    setCurrentChange (val) {
      this.currentPage = val;
      this.getList();
    },
    /**格式化地区 */
    getNationProvinceUrban (row) {
      let c = this.countryList.find(function (c) { return row.country == c.id });
      let r = this.countryList.find(function (c) { return row.region == c.id });
      let cr = (c ? c.name : '') + ' / ' + (r ? r.name : '')
      return cr;
    },
    drawerOpen () { this.drawer = true; },
    /**提交新增新机构 */
    onSubmit (formRef) {
      let _this = this;
      _this.$refs[formRef].validate(valid => {
        if (valid) {
          _this.isLoading = true;
          org.addOrg({
            param: _this[formRef],
            success: res => {
              if (res.code === 200) {
                _this.$message({ type: "success", message: _this.$t('operateSuccessfully') });
                _this.drawer = false;
                _this.getList();
              }
              else _this.$message({ type: "warning", message: _this.$t(res.msg) });
              _this.isLoading = false;
            }
          })
        }
        else {
          return false
        }
      })
    },
    /**获取角色信息 */
    getRoleList () {
      let _this = this;
      role.getAllRole({
        success: res => {
          if (res.code != 200) return;
          _this.roleList = res.data;
        }
      })
    },

    onLock (row, status) {
      console.log(row)
      row.userName = row.name;
      row.orgTypeName = this.$t(row.orgType == 1 ? 'platform' : row.orgType == 2 ? 'directSaleStore' : row.orgType == 2 ? 'allianceBusiness' : '');

      this.lockAccount.row = row;
      this.lockAccount.form = { orgInfoId: row.id, status: status };
      this.lockAccount.drawer = true;
    },
    onSubmitLock (refName) {
      this.$refs[refName].validate(valid => {
        if (!valid) return false;
        org.updateOrgInfoStatus({
          param: this.lockAccount.form,
          success: res => {
            if (res.code == 200) {
              this.getList();
              this.lockAccount.drawer = false;
              this.$message.success(this.$t('operateSuccessfully'));
            }
            else
              this.$message.warning(this.$t(res.msg));
          }
        })
      })
    }
  },
  created () { },
  mounted () {
    let queryTableHeight = this.$refs.pageHead.offsetHeight + 15;
    this.queryTableHeight = queryTableHeight + 'px';
    this.getList();
    this.getRoleList();
    // this.getOrgList();
  },
}
</script>

<style lang="less" scoped>
</style>